/* You can add global styles to this file, and also import other style files */
/** Generated by FG **/

/* @import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bs-stepper/dist/css/bs-stepper.min.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face { */
  /* font-family: 'Conv_AvenirNextLTPro-Medium';
	src: url('./assets/dist/fonts/fontsAvenir/AvenirNextLTPro-Medium.eot');
  src: local('☺'), url('./assets/dist/fonts/fontsAvenir/AvenirNextLTPro-Medium.woff') format('woff'), url('./assets/dist/fonts/fontsAvenir/AvenirNextLTPro-Medium.ttf') format('truetype'), url('./assets/dist/fonts/fontsAvenir/AvenirNextLTPro-Medium.svg') format('svg'); */
  /* font-family: 'Acumin Pro';
  src: local('Acumin Pro'), url('./assets/dist/fonts/acumin-pro-cufonfonts-webfont/Acumin-RPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */


/* ======================== */
.modal-open .modal .modal-xl {
  width: 90% !important;
}

body {
  /* font-family: 'Roboto', sans-serif !important; */
  /* font-family: 'Acumin Pro' !important; */
  font-family: 'Open Sans', sans-serif;
}

.ng-dropdown-header {
  padding: 0px !important;
  cursor: pointer;
}

i {
  margin-right: 4px !important;
}

.ng-value-container {
  cursor: pointer;
}

.page-wrapper .customViewBlock .panel .customHeadingBlock h3 {
  margin-top: 0px !important;
  /* font-family: 'Roboto', sans-serif ; */
  font-family: 'Acumin Pro' !important;
  margin-bottom: 0px !important;
  font-weight: 600;
}

.btn-group>.btn:not(:last-child) {
  border-right: 2px solid #eee !important;
  outline: 0px !important;
}

.page-wrapper .customViewBlock .panel .table-responsive thead {
  /* background: #a29f9f; */
  color: #656565;
}

table.dataTable thead th {
  padding-left: 10px !important;
  border-bottom: 1px solid #a9a9a9;
}

.page-wrapper .customViewBlock .panel .customHeadingBlock .btn {
  /* background: #0078ca; */
  background: #1b2b47 !important;
  border: none;
  font-weight: 600;
  border-radius: 5px;
  padding: 5px 10px;
  outline: 0px !important;
}

.btn {
  outline: 0px !important;
  border: 0px;
}

.page-wrapper .customViewBlock .panel .customHeadingBlock .btn:hover {
  background: #0b64a4;
  outline: 0px !important;
}

.dataTables_filter input[type=search] {
  height: 40px;
  padding: 0 5px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  /* background: #7a858e !important; */
  background: #000 !important;
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 5px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.last:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.first:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover {
  background: #333333 !important;
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 0px;
}

input[type=text],
input[type=number],
input[type=time],
input[type=email],
input[type=password],
input[type=url],
input[type=file]:not(.dn) {
  height: 40px;
  padding: 0 5px; 
  margin-top: 2px;
}

input[type=file] {
  padding: 0px 5px 8px !important;
}

/* textarea, */
select {
  border-radius: 5px;
  height: 40px !important;
  padding: 5px 5px;
}

input[type=time]::-webkit-inner-spin-button,
input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.modal-title {
  /* font-family: 'Roboto', sans-serif ; */
  font-family: 'Acumin Pro';
  text-transform: uppercase;
  font-size: 22px;
}

.modal-header {
  padding-top: 10px;
  padding-bottom: 5px;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.model-content-body {
  margin-top: -35px;
}

.model-container {
  /* border: 1px solid #1b2b47; */
  /* border-radius: 5px 5px 0px 0px; */
  margin-top: 15px;
}

.form-title {
  background: #1b2b47;
  /* border-radius: 5px 5px 0px 0px; */
  height: 40px;
  color: #fff;
  margin-bottom: 15px;
}

.model-details-heading {
  font-size: 18px;
  float: left;
  line-height: 0px;
}

.custom-btn-small {
  background: #0078ca;
  border: none;
  border-radius: 5px;
}

.custom-btn-small:hover {
  background: #3a4b69;
  color: #fff;
}

.custom-btn-larg,
.dropdown-content .custom-btn-larg {
  background: #1b2b47;
  border: none;
  width: 20%;
  padding: 10px 12px;
  border-radius: 5px;
}

.dropdown-content .custom-btn-larg {
  margin: 0 5px;
}

.custom-btn-larg:hover,
.dropdown-content .custom-btn-larg:hover {
  background: #3a4b69;
  color: #fff;
}

.dropdown-content .custom-btn-reset {
  background: #324148 !important;
  border: none;
  width: 20%;
  padding: 10px 12px;
  margin: 0 5px;
}

.custom-btn-reset {
  background: #324148 !important;
  border: none !important;
  color: #fff;
}

.dropdown-content .custom-btn-larg:hover {
  background: #0b64a4;
  color: #fff;
}

.custom-btn-md {
  background: #0078ca;
  border: none;
  width: 13%;
  padding: 7px 12px;
  border-radius: 5px
}

.custom-btn-md:hover {
  background: #0b64a4;
  color: #fff;
}

.page-wrapper .customViewBlock .panel .customHeadingBlock .custom-search-btn {
  /* background: #3cad1c8f; */
  background: #0078ca;
  border: none;
}

.page-wrapper .customViewBlock .panel .customHeadingBlock .custom-search-btn:hover,
.page-wrapper .customViewBlock .panel .customHeadingBlock .custom-search-btn:focus {
  /* background: #3cad1cc4; */
  background: #0b64a4;
  color: #fff;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  font-weight: 300 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: pointer !important;
}

.custom-alert {
  color: red;
  font-size: 8px;
  top: -1px;
  margin-left: 3px;
}

.nav-tabs>li:first-child>a {
  border-radius: 0px;
}

.nav-tabs>li:last-child>a {
  border-top-right-radius: 0px;
}

/* .nav-pills .nav-item:hover {
  background-color: #eee !important;
}

.nav-pills .nav-item:hover a {
  color: #727272 !important;
}

.nav-pills .nav-item.active:hover a {
  color: #fff !important;
} */

.nav-tabs>li>a {
  border-radius: 0px;
}

.nav-tabs>li {
  margin-right: 0px;
}

.nav>li.disabled>a:focus,
.nav>li.disabled>a:hover {
  background-color: #eee !important;
}

.CustomCharLimit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  display: block;
}

.custom-fa-action {
  font-size: 16px;
  color: #929292;
  cursor: pointer;
  margin: 0 10px;
}

.custom-fa-action:hover,
.custom-fa-action i:hover {
  color: #23527c;
}

.officeAction {
  cursor: pointer;
  /* font-weight: 550; */
}

.officeAction:hover {
  text-decoration: none;
}

.page-wrapper .customViewBlock .panel-body {
  padding-top: 0 !important;
}

hr {
  margin-top: 0px;
  margin-bottom: 15px;
}

.horizontal-nav .fixed-sidebar-left .side-nav>li>a {
  padding: 10px 15px;
}

.horizontal-nav .fixed-sidebar-left .side-nav>li>a:before {
  background: #1f8ceb !important;
}

.horizontal-nav .fixed-sidebar-left .side-nav>li>a:hover span {
  color: #1f8ceb !important;
}

.fixed-sidebar-left .side-nav>li>ul>li a:hover {
  color: #1f8ceb !important;
}

.horizontal-nav .fixed-sidebar-left .side-nav>li>ul>li a:hover span {
  color: #1f8ceb !important;
}

.label.label-success {
  /* background: #26b1ff8a;
  color: #676666; */
  background: #72e3d9 !important;
  color: #fff;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500 !important;
}

.label.label-danger {
  background: #b7aeb1;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500 !important;
}

.alert.alert-warning {
  background: #17a2b88c;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
}

.alert-warning {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.alert.alert-warning a {
  color: white;
  text-shadow: none;
  text-decoration: underline;
}

.table>thead>tr.custom_th>th {
  padding: 10px 18px !important;
  font-weight: bold;
  /* background: #a29f9f !important; */
  color: #676666 !important;
  font-size: 14px;
  text-transform: capitalize;
}

.table>thead>tr.custom_th {
  border-bottom: 1px solid #a9a9a9 !important;
}

.table>thead>tr.custom_th>th {
  border-bottom: none !important;
}

.dt-buttons .dt-button {
  background: #0078ca !important;
  border: none !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
  padding: 8px 25px 4px 25px !important;
}

.ng-select {
  border-radius: 0px;
}

.ng-select-container {
  border-radius: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0px;
  padding-left: 7px;
}

#selectOffice.ng-select.ng-select-single .ng-select-container {
  /* height: 40px !important; */
  height: 30px !important;
  background-color: #1b2a47e3 ;
  width: 120px ;
  color: #fff ;
  border-radius: 5px !important;
  margin-top: 8px ;
  float: right;
}

.horizontal-nav .fixed-sidebar-left>.slimScrollDiv>ul>li {
  vertical-align: middle !important;
}

.close {
  opacity: 1;
}

.close:hover {
  opacity: 0.5;
}

.customer .dataTables_empty {
  display: none;
}

.left-ponter {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 0;
  padding-right: 0;
  float: left;
}

.list-group li.list-group-item-active {
  border: 1px solid #0b64a4;
  border-radius: 50%
}

.custom-modal {
  overflow-y: scroll;
  height: 300px;
}

table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting::after,
table.dataTable thead .sorting_desc::after {
  content: none !important;
}

.qoutes {
  margin-top: 6px;
}

table.dataTable.row-border tbody td,
table.dataTable>thead:first-child>tr:first-child>th {
  /* border-left: 1px solid #ddd !important; */
  border-left: none;
  /* color: #676666; */
  color: #282020;
  /* font-weight: 300; */
}

table.dataTable.row-border tbody td {
  font-size: 13px;
}

table.dataTable.row-border tbody td:last-child,
table.dataTable>thead:first-child>tr:first-child>th:last-child {
  /* border-right: 1px solid #ddd !important; */
  border-right: none;
}

blockquote {
  background: #EAF8EC;
  border-left: 5px solid #B5D2BB;
  margin: 0px;
}

blockquote p {
  margin-bottom: 0px;
}

.cust-file-btn {
  min-height: 44px !important;
  border-radius: 0px;
  padding: 5px
}

.cust-file-btn label {
  padding: 5px 12px;
  border-radius: 0px;
  margin-right: 3px;
}

.fixed-sidebar-left .side-nav li a {
  text-transform: uppercase !important;
  font-size: 11px !important;
}

.qoutesShowless {
  height: 22px;
  overflow: hidden;
  margin: 0px;
}

.qoutebtn a {
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.disabledCurser {
  cursor: not-allowed;
}

.panel-info>.panel-heading {
  background-color: #fff;
}

.panel-heading {
  color: #31708f;
  font-size: 18px;
  border-bottom: none;
}

.fa-arrow-circle-down {
  font-size: 18px;
}

.tableID {
  padding: 15px 10px;
}

.tableID p {
  margin: 0px;
}

.tableID p span {
  font-size: 11px;
}

.tableBlock {
  margin: 20px 0px;
}


.viewTable tbody tr th strong,
.viewTable p span {
  color: #6766669c;
  font-weight: 500;
  font-size: 13px;
}

.viewTable tbody tr th,
.viewTable tbody tr td {
  border: none;
}

.viewTable input[type="text"],
.viewTable input[type="text"]:hover,
.viewTable input[type="text"]:focus,
.viewTable input[type="text"]:active,
.viewTable input[type="number"],
.viewTable input[type="number"]:hover,
.viewTable input[type="number"]:focus,
.viewTable input[type="number"]:active,
.viewTable input[type="email"],
.viewTable input[type="email"]:hover,
.viewTable input[type="email"]:focus,
.viewTable input[type="email"]:active,
/* .viewTable textarea,
.viewTable textarea:hover,
.viewTable textarea:focus,
.viewTable textarea:active .viewTable select {
  border: 0px;
  border-bottom: 1px solid #8e8e8e;
  box-shadow: inset 0 0px 1px rgba(0, 0, 0, .075);
  height: 30px !important;
} */

.viewTableEditBlock {
  margin-bottom: 20px;
}

.viewTableEditBlock .viewTableHeading {
  background: none;
  text-align: initial;
  margin-bottom: 0px;
}

.modelDetailHeading {
  text-transform: uppercase;
  margin-bottom: 20px !important;
  margin-top: 0px !important;
  font-size: 20px;
  padding: 5px 0 2px;
  float: left;
  border-bottom: 2px solid #999;
  width: 100%;
  text-align: left;
}

.viewTableHeading {
  text-transform: uppercase;
  margin-top: 0px !important;
  font-size: 20px;
  padding: 5px 5px;
  background: #ccc;
  text-align: initial;
  margin-bottom: 6px;
}

.cancel {
  background: #324148 !important;
  border: 1px solid #324148 !important;
}

.save {
  background: #26b1ff !important;
  border: 1px solid #26b1ff !important;
}

.viewTable .ng-select .ng-select-container {
  border: none;
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 10px;
}

.fixed-sidebar-left .right-nav-text {
  /* font-size: 13px !important; */
  text-transform: uppercase !important;
  color: #fff !important;
  font-weight: 500;
}

table.dataTable.row-border tbody tr td,
table.table tbody tr td {
  vertical-align: middle !important;
}


/*============================= Media Query =======================================*/

@media screen and (max-width: 767px) {
  .tableBlock {
    margin: 20px 0px;
  }

}

@media (max-width:1050px) {
  .page-wrapper .customViewBlock .panel .customHeadingBlock h3 {
    margin-bottom: 10px !important;
  }
}

.pannel-expend {
  width: calc(100% - 220px) !important;
  margin-left: 210px !important;
}

.pannel-closed {
  width: calc(100% - 50px) !important;
  margin-left: 50px !important;
}

.header-info {
  font-size: 15px;
  color: gray;
  cursor: pointer;
}



.tooltip1 {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */

  /* float: right; */
  margin-top: -28px;
  /* margin-right: 135px; */
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  width: 320px;
  background-color: #1b2b47;
  color: #fff;
  text-align: justify;
  border-radius: 6px;
  padding: 15px;
  font-size: 12px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}

.table-responsive {
  margin-top: 0px;
}


.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
  background-color: #1b2b47 !important;
  color: #fff !important;
}

.invalid {
  border-left: 4px solid #dc3545;
}

@media (max-width :1199px) {
  /* .pannel-expend {
    width: calc(100% - 20px) !important;
    margin-left: 20px !important;
  } */

  .pannel-closed {
    width: 100% !important;
    margin-left: 0 !important;
    padding: 20px;
  }
}

#notes,
#office_notes {
  /* height: 100px !important; */
  resize: vertical !important;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border: 1px solid skyblue;
  margin-bottom: 0px;
}
.cropText {
  max-width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#multi-select .multiselect-dropdown .dropdown-list .item1 .filter-textbox{
  padding : 0px !important
}
#multi-select .multiselect-dropdown .dropdown-list .item1 .filter-textbox input[type=text]{
  height : 30px !important
}
/* .col-sm {
  flex-basis: 0 !important;
  flex-grow: 1 !important;
  max-width: 100% !important;
  display: inline-flex;
} */
.circle {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: #ddd;
  border-radius: 50%;
}

.inner-circle {
  width: 92%;
  height: 92%;
  background: white;
  border-radius: 50%;
  margin: auto;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.spinner {
  height: 0;
  width: 0;
  border-radius: 50%;
  border-right: 21px solid rgba(0, 0, 0, 1);
  border-top: 31px solid transparent;
  border-left: 30px solid transparent;
  border-bottom: 21px solid transparent;

  animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.readonly-bg-color{
  background-color: #ffffff !important;
}

 pagination-controls.pagination-gap.ng-star-inserted {
  float: left;
}
.pagination-gap .ngx-pagination .current  {
  background: #374955 !important;
  padding: 7px 14px !important;
  margin: 0px !important;
  color: #fff !important;
}
.pagination-gap .ngx-pagination  {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  float: left;
  font-size: 13px;
  margin-left: 10px !important;
}
.pagination-gap .ngx-pagination li {
  border-radius: 0 !important;
  padding: 0px !important;
  border: 1px solid #e5e5e5 !important;
}
.pagination-gap .ngx-pagination li a {
  padding: 6px 14px !important;
}

.pagination-gap .ngx-pagination a:hover, .ngx-pagination button:hover{
  background: #e6e6e6;
}

.pagination-gap .ngx-pagination li.pagination-next.disabled {
  padding: 6px 14px !important;
}

.pagination-gap .ngx-pagination li.pagination-previous.disabled {
  padding: 6px 14px !important;
}
.mt-3{
  margin-top: 1rem !important
}

.make-point {
  cursor: pointer;
  color: #0587b7;
}
foreignObject{
  width: 100%;
}
app-recruit-list .search-dropdown.ng-select.ng-select-single .ng-select-container {
  /* height: 40px !important; */
  height: 41px !important;
  background-color: #fff;
  width: 100%;
  color: #000;
  border-radius: 6px;
  margin-top: 1px;
  line-height: 41px;

  /*border: 1px solid #4f4f4f;*/
}

app-recruit-list .search-dropdown.ng-select.ng-select-multiple .ng-select-container {
  /* height: 40px !important; */
  height: 30px !important;
  background-color: #fff;
  width: 120px;
  color: #000;
  border-radius: 2px;
  margin-top: 8px;
  float: right;
}

app-add-edit-contract .templateId.ng-select.ng-select-single .ng-select-container {
  /* height: 40px !important; */
  /*height: 41px !important;*/
  background-color: #fff;
  width: 100%;
  color: #000;
  border-radius: 2px;
  margin-top: 1px;
  float: left;
  /*border: 1px solid #4f4f4f;*/
}

.my-pagination .ngx-pagination .current{
  background-color: #EAECF0;
  color: #182230;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: block;
  padding: 10px 15px 10px 15px;
}
.my-pagination .ngx-pagination li a:hover{
  background-color: #EAECF0;
  border-radius: 50%;
  text-decoration: none;
}

.my-pagination .ngx-pagination a,.my-pagination .ngx-pagination button {
  font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #475467;
  display: block;
  padding: 10px 15px 10px 15px;
  border-radius: 0;
}

.my-pagination .ngx-pagination .pagination-next a:after, .my-pagination .ngx-pagination .pagination-next.disabled:after {
  content: "->";
  display: inline-block;
  margin-left: 5px;
  font-weight: 500 !important;
  font-size: 20px;
  margin-top: -20px;
  margin-bottom: -5px;
}
/* // .my-pagination .ngx-pagination .pagination-previous a:before, .ngx-pagination .pagination-previous.disabled span:before { */
  .my-pagination .ngx-pagination .pagination-previous a:before, .my-pagination .ngx-pagination .pagination-previous.disabled:before{
  content: "<-";
  display: inline-block;
  margin-right: 5px;
  font-weight: 500 !important;
  font-size: 20px;
  margin-top: -20px;
  margin-bottom: -5px;
}

.my-pagination .ngx-pagination{
  padding: 0px
}

.my-pagination .ngx-pagination {
  display: flex;
  align-items: center;
}

.my-pagination .ngx-pagination .pagination-previous {
  margin-right: auto; 
}

.my-pagination .ngx-pagination .pagination-previous a:hover{
border-radius: 0%;
}

.my-pagination .ngx-pagination .pagination-next a:hover{
  border-radius: 0%;
  }
.my-pagination .pagination-page li a{
  margin: 0 5px; 
}

.my-pagination .ngx-pagination .pagination-next {
  margin-left: auto; 
}



.my-pagination .ngx-pagination li a:hover{
  background-color: #EAECF0;
  border-radius: 50%;
  /* //   height: 35px;
  //   width: 35px;
    // padding: 10px 15px 10px 15px; */
}



/* // .my-pagination .ngx-pagination .pagination-next {
//   // background-color: whi;
//   color: #182230;
// } */

.my-pagination .ngx-pagination .pagination-next a:after, .my-pagination .ngx-pagination .pagination-next.disabled:after {
  content: ">>";
  display: inline-block;
  margin-left: 5px;
  font-weight: 500 !important;
  font-size: 16px;
  margin-top: -20px;
  margin-bottom: -5px;
}
/* // .my-pagination .ngx-pagination .pagination-previous a:before, .ngx-pagination .pagination-previous.disabled span:before { */
  .my-pagination .ngx-pagination .pagination-previous a:before, .my-pagination .ngx-pagination .pagination-previous.disabled:before{
  content: "<<";
  display: inline-block;
  margin-right: 5px;
  font-weight: 500 !important;
  font-size: 16px;
  margin-top: -20px;
  margin-bottom: -5px;
}

.my-pagination .ngx-pagination{
  padding: 0px
}

.my-pagination .ngx-pagination {
  display: flex;
  align-items: center;
}

.my-pagination .ngx-pagination .pagination-previous {
  margin-right: auto; 
}

.my-pagination .ngx-pagination .pagination-previous a:hover{
border-radius: 0%;
}

.my-pagination .ngx-pagination .pagination-next a:hover{
  border-radius: 0%;
  }
.my-pagination .pagination-page li a{
  margin: 0 5px; 
}

.my-pagination .ngx-pagination .pagination-next {
  margin-left: auto; 
}

body .status-change-dropdown.ng-select.ng-select-single .ng-select-container {
  background-color: #fff;
  width: 100%;
  color: #000;
  border-radius: 2px;
  margin-top: 8px;
}
.modal-text-white{
  color: #fff !important;
}